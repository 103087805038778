/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    div: "div",
    span: "span",
    math: "math",
    semantics: "semantics",
    mrow: "mrow",
    msub: "msub",
    mi: "mi",
    mo: "mo",
    mn: "mn",
    msup: "msup",
    mfrac: "mfrac",
    mtext: "mtext",
    mspace: "mspace",
    annotation: "annotation"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "本文旨在按字母顺序对团队研究过程中涉及的术语进行分类解释，为对该领域感兴趣的读者提供一份全面的术语表。"), "\n", React.createElement(_components.h3, null, "Feature Engineering"), "\n", React.createElement(_components.p, null, "++Need to Fill++"), "\n", React.createElement(_components.h3, null, "Few-shot Learning"), "\n", React.createElement(_components.p, null, "++Need to Fill++"), "\n", React.createElement(_components.h3, null, "Filter"), "\n", React.createElement(_components.p, null, "滤波器（Filter）也称为卷积核（Kernel）。在卷积神经网络（CNN）中，通常使用监督学习（supervised learning）的方法，这意味着需要通过标记的图像（labelled images）来训练CNN。\nCNN通过训练来优化卷积滤波器的权重（weight）并最小化误差，从而自己形成适合最小化误差的滤波器形状。设置滤波器的大小在训练过程中非常重要，因为这样可以确保在处理输入图像时滤波器能够提取有意义的特征（features）。"), "\n", React.createElement(_components.h3, null, "F-measure / F1 score"), "\n", React.createElement(_components.p, null, "F度量（F-measure）是将准确率（Precision）和召回率（Recall）结合在一起，用一个指标来表示的。\n在下面的公式中，参数λ决定了准确率和召回率之间的权重分配。例如，当λ取1时，即表示更加重视召回率而不是准确率。在F度量中，最常用的指标是λ取1，即将准确率和召回率视为同等重要。"), "\n", React.createElement(_components.div, {
    className: "math math-display"
  }, React.createElement(_components.span, {
    className: "katex-display"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML",
    display: "block"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.msub, null, React.createElement(_components.mi, null, "F"), React.createElement(_components.mi, null, "β")), React.createElement(_components.mo, null, "="), React.createElement(_components.mrow, null, React.createElement(_components.mo, {
    fence: "true"
  }, "("), React.createElement(_components.mn, null, "1"), React.createElement(_components.mo, null, "+"), React.createElement(_components.msup, null, React.createElement(_components.mi, null, "β"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, {
    fence: "true"
  }, ")")), React.createElement(_components.mfrac, null, React.createElement(_components.mrow, null, React.createElement(_components.mtext, null, " Precision "), React.createElement(_components.mo, null, "×"), React.createElement(_components.mtext, null, " Recall ")), React.createElement(_components.mrow, null, React.createElement(_components.msup, null, React.createElement(_components.mi, null, "β"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, null, "×"), React.createElement(_components.mtext, null, " Precision "), React.createElement(_components.mo, null, "+"), React.createElement(_components.mtext, null, " Recall "))), React.createElement(_components.mspace, {
    width: "1em"
  }), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "F"), React.createElement(_components.mn, null, "1")), React.createElement(_components.mo, null, "="), React.createElement(_components.mfrac, null, React.createElement(_components.mrow, null, React.createElement(_components.mn, null, "2"), React.createElement(_components.mo, null, "×"), React.createElement(_components.mtext, null, " Precision "), React.createElement(_components.mo, null, "×"), React.createElement(_components.mtext, null, " Recall ")), React.createElement(_components.mrow, null, React.createElement(_components.mtext, null, " Precision "), React.createElement(_components.mo, null, "+"), React.createElement(_components.mtext, null, " Recall ")))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "F_\\beta=\\left(1+\\beta^2\\right) \\frac{\\text { Precision } \\times \\text { Recall }}{\\beta^2 \\times \\text { Precision }+ \\text { Recall }} \\quad F_1=\\frac{2 \\times \\text { Precision } \\times \\text { Recall }}{\\text { Precision }+ \\text { Recall }}")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.9694em",
      verticalAlign: "-0.2861em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.13889em"
    }
  }, "F"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3361em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "-0.1389em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05278em"
    }
  }, "β")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.2861em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "2.2519em",
      verticalAlign: "-0.8804em"
    }
  }), React.createElement(_components.span, {
    className: "minner"
  }, React.createElement(_components.span, {
    className: "mopen delimcenter",
    style: {
      top: "0em"
    }
  }, React.createElement(_components.span, {
    className: "delimsizing size1"
  }, "(")), React.createElement(_components.span, {
    className: "mord"
  }, "1"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05278em"
    }
  }, "β"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8641em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-3.113em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))))))), React.createElement(_components.span, {
    className: "mclose delimcenter",
    style: {
      top: "0em"
    }
  }, React.createElement(_components.span, {
    className: "delimsizing size1"
  }, ")"))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.1667em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mopen nulldelimiter"
  }), React.createElement(_components.span, {
    className: "mfrac"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "1.3714em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.314em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05278em"
    }
  }, "β"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.7401em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.989em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "×"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mord text"
  }, React.createElement(_components.span, {
    className: "mord"
  }, " Precision ")), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mord text"
  }, React.createElement(_components.span, {
    className: "mord"
  }, " Recall ")))), React.createElement(_components.span, {
    style: {
      top: "-3.23em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "frac-line",
    style: {
      borderBottomWidth: "0.04em"
    }
  })), React.createElement(_components.span, {
    style: {
      top: "-3.677em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord text"
  }, React.createElement(_components.span, {
    className: "mord"
  }, " Precision ")), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "×"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mord text"
  }, React.createElement(_components.span, {
    className: "mord"
  }, " Recall "))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8804em"
    }
  }, React.createElement(_components.span))))), React.createElement(_components.span, {
    className: "mclose nulldelimiter"
  })), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "1em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.13889em"
    }
  }, "F"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3011em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "-0.1389em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "1")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "2.1408em",
      verticalAlign: "-0.7693em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mopen nulldelimiter"
  }), React.createElement(_components.span, {
    className: "mfrac"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "1.3714em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.314em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord text"
  }, React.createElement(_components.span, {
    className: "mord"
  }, " Precision ")), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mord text"
  }, React.createElement(_components.span, {
    className: "mord"
  }, " Recall ")))), React.createElement(_components.span, {
    style: {
      top: "-3.23em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "frac-line",
    style: {
      borderBottomWidth: "0.04em"
    }
  })), React.createElement(_components.span, {
    style: {
      top: "-3.677em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord"
  }, "2"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "×"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mord text"
  }, React.createElement(_components.span, {
    className: "mord"
  }, " Precision ")), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "×"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mord text"
  }, React.createElement(_components.span, {
    className: "mord"
  }, " Recall "))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.7693em"
    }
  }, React.createElement(_components.span))))), React.createElement(_components.span, {
    className: "mclose nulldelimiter"
  }))))))), "\n", React.createElement(_components.h3, null, "Fully Connected Layer"), "\n", React.createElement(_components.p, null, "全连接层（Fully Connected Layer）也被称为密集层（Dense Layer）。每个节点都与后续隐藏层（Hidden Layer）的所有节点相连接，因此被称为全连接。"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
